import {Component, OnDestroy, OnInit} from '@angular/core';
import {ICalculation} from "../../../../shared/interfaces/calculation.interface";
import {Subscription} from "rxjs";
import {CalculationService} from "../../../../shared/services/calculation.service";
import {CalculationRestService} from "../../../../shared/services/rest-services/calculation-rest.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HelperService} from "../../../../shared/services/helper.service";
import {FleetService} from "../../../../shared/services/fleet.service";

@Component({
  selector: 'app-markup-overview',
  templateUrl: './markup-overview.component.html'
})
export class MarkupOverviewComponent implements OnInit, OnDestroy {
  public markupCalculations: ICalculation[] = [];
  public markupSubscription: Subscription;
  public closeResult: string;

  constructor(public calculationService: CalculationService,
              public calculationRestService: CalculationRestService,
              private modalService: NgbModal,
              public helperService: HelperService,
              public fleetService: FleetService) {
  }

  ngOnInit() {
    this.markupSubscription = this.calculationService.calculations.subscribe(
      (data) => {
        if (data) {
          this.markupCalculations = data;
          this.markupCalculations.sort((a, b) => {
            return a.sortNo - b.sortNo;
          });
        } else {
          this.markupCalculations = [];
        }
      });
  }

  ngOnDestroy() {
    this.markupSubscription.unsubscribe();
  }

  public open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, () => {
      this.closeResult = `Dismissed`;
    });
  }

  public deleteCalculation(id: number) {
    this.calculationRestService.deleteCalculation(id).subscribe(data => {
      this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'MARKUP');
      this.calculationService.resetCalculationIds();
    });
  }

}
