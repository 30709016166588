<form name="form" class="mt-3" (ngSubmit)="markupForm.form.valid && saveMarkupCalculation()"
      #markupForm="ngForm">
    <div *ngIf="markupCalculation" class="row no-gutters data-box">
        <div class="col-12 mb-3">
            <div class="row">
                <div class="col-12">
                    <input type="checkbox" class="toggle toggle-primary" id="active"
                           [(ngModel)]="markupCalculation.active" name="active">
                    <label for="active">{{ 'MARKUP.ACTIVE' | translate }}</label>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12">
            <div class="form-group row">
                <label for="customId" class="col-2 col-form-label">{{ 'MARKUP.DESCRIPTION' | translate }}</label>
                <div class="col-10">
                    <input type="text" id="description" class="form-control" [(ngModel)]="markupCalculation.description"
                           name="description" required/>
                    <span class="show-required-star"></span>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12">
            <div class="form-group row">
                <label for="customId" class="col-2 col-form-label">{{ 'MARKUP.CUSTOM_ID' | translate }}</label>
                <div class="col-10">
                    <input type="text" id="customId" class="form-control" [(ngModel)]="markupCalculation.customId"
                           name="customId"/>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12">
            <div class="form-group row">
                <label for="genArt" class="col-2 col-form-label">{{'MARKUP.GEN_ART' | translate}}</label>
                <div class="col-10">
                    <input id="genArt" type="text" name="genArt"
                           class="form-control" #name="ngModel"
                           placeholder="{{'MARKUP.ALL_ARTICLES' | translate}}"
                           [ngbTypeahead]="dataService.searchGenArt"
                           [container]="'body'"
                           (selectItem)="setGenArt($event)"
                           [resultFormatter]="helperService.formatterDescriptionWithId"
                           [inputFormatter]="helperService.formatterDescriptionWithId"
                           [editable]='false'
                           [(ngModel)]="_genArt"/>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12">
            <div class="form-group row">
                <label for="material-code" class="col-2 col-form-label">{{'MARKUP.MC' | translate}}</label>
                <div class="col-10">
                    <input id="material-code" type="text" class="form-control" #name="ngModel"
                           [(ngModel)]="markupCalculation.materialCode"
                           name="material-code" >
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-primary font-weight-bold">
                    {{ 'MARKUP.FLEET' |translate }}:
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12">
            <div class="form-group row">
                <label for="fOp2" class="col-2 col-form-label">{{ 'MARKUP.OPERATION_TYPE' | translate }}</label>
                <div class="col-10">
                    <select required type="text" class="form-control"
                            [(ngModel)]="markupCalculation.computationMap.Flotte.operation.split('_')[1]" name="fOp2"
                            id="fOp2">
                        <option value="CHARGE" selected>{{ 'MARKUP.OPERATION_TYPES.CHARGE' | translate }}</option>
                    </select>
                    <span class="show-required-star"></span>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12">
            <div class="form-group row">
                <label for="fOp1" class="col-2 col-form-label">{{ 'MARKUP.OPERATION' | translate }}</label>
                <div class="col-10">
                    <select required type="text" class="form-control"
                            [(ngModel)]="markupCalculation.computationMap.Flotte.operation.split('_')[0]" name="fOp1"
                            id="fOp1">
                        <option value="" selected disabled hidden>{{ 'FORM.CHOOSE' | translate }}</option>
                        <option value="PERCENTAL">%</option>
                        <option value="CONST">{{ helperService.getCurrencySmbol() | currencySymbol }}</option>
                    </select>
                    <span class="show-required-star"></span>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-12">
            <div class="form-group row">
                <label for="fvalue" class="col-2 col-form-label">{{ 'MARKUP.AMOUNT' | translate }}</label>
                <div class="col-10">
                    <input id="fvalue" type="number" class="form-control"
                           [(ngModel)]="markupCalculation.computationMap.Flotte.value"
                           pattern="[0-9]+([\.,][0-9]+)?" step="0.01" name="fvalue" required>
                    <span class="show-required-star"></span>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12  button-group">
                    <button type="submit" [disabled]="!(markupForm.form.valid)"
                            class="btn btn-primary float-left">{{ 'BTN.SAVE' | translate }}
                    </button>
                    <button type="button" (click)="helperService.locationBackfromMenu()"
                            class="btn btn-outline-secondary float-right">{{ 'BTN.CANCEL' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
