import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './auth/login/login.component';
import {FleetComponent} from './fleet/fleet.component';
import {AuthGuard} from './auth/guards/auth/auth.guard';
import {RootComponent} from './root/root.component';
import {OeArticleComponent} from './fleet/calculations/oe-article/oe-article.component';
import {FixedPriceComponent} from './fleet/calculations/fixed-price/fixed-price.component';
import {AftermarketComponent} from './fleet/calculations/aftermarket/aftermarket.component';
import {HourlyWageComponent} from './fleet/calculations/hourly-wage/hourly-wage.component';
import {NewFleetComponent} from './fleet/new-fleet/new-fleet.component';
import {FleetOverviewComponent} from './fleet/fleet-overview/fleet-overview.component';
import {NewAftermarketCalculationComponent} from './fleet/calculations/aftermarket/new-aftermarket-calculation/new-aftermarket-calculation.component';
import {AftermarketCalculationComponent} from './fleet/calculations/aftermarket/aftermarket-calculation/aftermarket-calculation.component';
import {NewFixedPriceCalculationComponent} from './fleet/calculations/fixed-price/new-fixed-price-calculation/new-fixed-price-calculation.component';
import {FixedPriceCalculationComponent} from './fleet/calculations/fixed-price/fixed-price-calculation/fixed-price-calculation.component';
import {OeArticleCalculationComponent} from './fleet/calculations/oe-article/oe-article-calculation/oe-article-calculation.component';
import {NewOeArticleCalculationComponent} from './fleet/calculations/oe-article/new-oe-article-calculation/new-oe-article-calculation.component';
import {HourlyWageCalculationComponent} from './fleet/calculations/hourly-wage/hourly-wage-calculation/hourly-wage-calculation.component';
import {NewHourlyWageCalculationComponent} from './fleet/calculations/hourly-wage/new-hourly-wage-calculation/new-hourly-wage-calculation.component';
import {FleetMenuComponent} from './fleet/fleet-menu/fleet-menu.component';
import {MaterialCodesComponent} from './fleet/material-codes/material-codes.component';
import {MaterialCodesViewComponent} from './fleet/material-codes/material-codes-view/material-codes-view.component';
import {NewMaterialCodesComponent} from './fleet/material-codes/new-material-codes/new-material-codes.component';
import {MarkupComponent} from "./fleet/calculations/markup/markup.component";
import {MarkupOverviewComponent} from "./fleet/calculations/markup/markup-overview/markup-overview.component";
import {NewMarkupComponent} from "./fleet/calculations/markup/new-markup/new-markup.component";


const routes: Routes = [
  {
    path: '',
    data: {state: 'root'},
    component: RootComponent,
    children: [
      {path: '', data: {state: 'empty_path_first'}, component: FleetComponent},
      {path: 'login', component: LoginComponent},
      {
        path: 'fleet', component: FleetComponent, canActivate: [AuthGuard], children: [
          {path: '', component: FleetOverviewComponent},
          {path: 'new_fleet', component: NewFleetComponent},
          {
            path: ':fleetId', component: FleetMenuComponent, children: [
              {path: '', component: FleetOverviewComponent},
              {path: 'edit_fleet', component: NewFleetComponent},
              {
                path: 'after_market', component: AftermarketComponent, children: [
                  {path: '', component: AftermarketCalculationComponent},
                  {path: 'new_aftermarket_calculation', component: NewAftermarketCalculationComponent},
                  {
                    path: ':calcId', children: [
                      {path: 'edit_aftermarket_calculation', component: NewAftermarketCalculationComponent}
                    ]
                  }
                ]
              },
              {
                path: 'material_codes', component: MaterialCodesComponent, children: [
                  {path: '', component: MaterialCodesViewComponent},
                  {path: 'new_material_codes', component: NewMaterialCodesComponent},
                  {
                    path: ':mcId', children: [
                      {path: 'edit_material_codes', component: NewMaterialCodesComponent}
                    ]
                  }
                ]
              },
              {
                path: 'markup', component: MarkupComponent, children: [
                  {path: '', component: MarkupOverviewComponent},
                  {path: 'new_markup', component: NewMarkupComponent},
                  {
                    path: ':mId', children: [
                      {path: 'edit_markup', component: NewMarkupComponent}
                    ]
                  }
                ]
              },
              {
                path: 'oe_part', component: OeArticleComponent, children: [
                  {path: '', component: OeArticleCalculationComponent},
                  {path: 'new_oe_part_calculation', component: NewOeArticleCalculationComponent},
                  {
                    path: ':calcId', children: [
                      {path: 'edit_oe_part_calculation', component: NewOeArticleCalculationComponent}
                    ]
                  },
                ]
              },
              {
                path: 'fixed_price', component: FixedPriceComponent, children: [
                  {path: '', component: FixedPriceCalculationComponent},
                  {path: 'new_fixed_price_calculation', component: NewFixedPriceCalculationComponent},
                  {
                    path: ':calcId', children: [
                      {path: 'edit_fixed_price_calculation', component: NewFixedPriceCalculationComponent}
                    ]
                  },
                ]
              },
              {
                path: 'hourly_wage', component: HourlyWageComponent, children: [
                  {path: '', component: HourlyWageCalculationComponent},
                  {path: 'new_hourly_wage_calculation', component: NewHourlyWageCalculationComponent},
                  {
                    path: ':hourlyWageCalcId', children: [
                      {path: 'edit_hourly_wage_calculation', component: NewHourlyWageCalculationComponent}
                    ]
                  },

                ]
              }
            ]
          }
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
