import {Component, OnDestroy, OnInit} from '@angular/core';
import {FleetService} from '../../shared/services/fleet.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {MaterialCodesService} from '../../shared/services/material-codes.service';

@Component({
  selector: 'app-material-codes',
  templateUrl: './material-codes.component.html'
})
export class MaterialCodesComponent implements OnInit, OnDestroy {
  public currentId: number;
  public Subscription: Subscription;


  constructor(private fleetService: FleetService,
              private materialCodesService: MaterialCodesService,
              private route: ActivatedRoute,
              private router: Router) {
    // if user navigates from 1 fleet to another  and naviagtes to the same component, NgOnit would not be triggered. solution via routing events and startUp call
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((url) => {
      this.getCurrentId();
      if (this.currentId != this.fleetService.fleetId.value && url['url'].split('/').pop() === 'material_codes') {
        this.startUpCheck();
      }
    })
  }

  ngOnInit() {
    this.startUpCheck();
    this.Subscription = this.fleetService.currentSubmenu.subscribe(submenu => {
      if (submenu !== 'MATERIAL_CODES') {
        this.fleetService.currentSubmenu.next('MATERIAL_CODES');
      }
    });
  }

  ngOnDestroy() {
    if (this.Subscription) {
      this.Subscription.unsubscribe();
    }
    this.fleetService.currentSubmenu.next(null);
  }

  public startUpCheck() {
    this.getCurrentId();
    if (this.currentId > 0) {
      this.fleetService.setCurrentFleetById(this.currentId);
      this.materialCodesService.getMaterialCodesByFleet(this.currentId);
    }
  }

  public getCurrentId() {
    const url = this.router.url.split('/');
    this.currentId = Number(url[2]);
  }

}
