<main class="root full-height full-width">
  <div class="nav-col full-height bg-dark text-light no-print">
    <app-tree-view></app-tree-view>
  </div>
  <div class="content-col full-height">
    <div class="container-fluid full-height overflow-auto p-5">
      <div class="row">
        <app-breadcrumb></app-breadcrumb>
      </div>
      <div class="row">
        <div class="col-xl-8 col-lg-12 p-0 pr-2">
          <router-outlet #route="outlet"></router-outlet>
        </div>
        <div class="col-xl-4 col-lg-12 p-0 pr-2 no-print"
             *ngIf="currentFleet">
          <app-fleet-info></app-fleet-info>
          <app-markup-calculation-info></app-markup-calculation-info>
          <app-aftermarket-calculation-info></app-aftermarket-calculation-info>
          <app-oe-article-calculation-info></app-oe-article-calculation-info>
          <app-fixed-price-calculation-info></app-fixed-price-calculation-info>
          <app-hourly-wage-info></app-hourly-wage-info>
          <app-find-calculation></app-find-calculation>
        </div>
      </div>
    </div>
  </div>
</main>
