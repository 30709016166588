import { Component, OnInit } from '@angular/core';
import {ICalculation} from "../../../../shared/interfaces/calculation.interface";
import {Subscription} from "rxjs";
import {CalculationService} from "../../../../shared/services/calculation.service";
import {FleetService} from "../../../../shared/services/fleet.service";
import {HelperService} from "../../../../shared/services/helper.service";
import {CalculationRestService} from "../../../../shared/services/rest-services/calculation-rest.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-markup-calculation-info',
  templateUrl: './markup-calculation-info.component.html'
})
export class MarkupCalculationInfoComponent implements OnInit {
  public isCollapsed = false;
  public closeResult: string;
  public currentMarkupCalculation: ICalculation;
  public currentMarkupCalculationSubscription: Subscription;

  constructor(public calculationService: CalculationService,
              private fleetService: FleetService,
              public helperService: HelperService,
              private calculationRestService: CalculationRestService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    this.currentMarkupCalculationSubscription = this.calculationService.currentCalculation.subscribe(
      data => this.currentMarkupCalculation = data);
  }

  ngOnDestroy() {
    this.currentMarkupCalculationSubscription.unsubscribe();
  }

  public deleteCalculation(id: number) {
    this.calculationRestService.deleteCalculation(id).subscribe(data => {
      this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'MARKUP');
      this.calculationService.resetCalculationIds();
    });
  }

  public activeChanged(event) {
    this.calculationRestService.updateCalculation(this.currentMarkupCalculation).subscribe(data => this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, this.fleetService.currentSubmenu.value));
  }

  public open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, () => {
      this.closeResult = `Dismissed`;
    });
  }
}
