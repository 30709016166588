import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {DragDropModule} from '@angular/cdk/drag-drop';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FleetComponent} from './fleet/fleet.component';
import {NavbarComponent} from './navbar/navbar.component';
import {HttpErrorInterceptor} from './shared/services/interceptor/http-error.interceptor';
import {ToastComponent} from './shared/components/toast/toast.component';
import {RootComponent} from './root/root.component';
import {AuthModule} from './auth/auth.module';
import {FleetInfoComponent} from './fleet/fleet-info/fleet-info.component';
import {TreeViewComponent} from './tree-view/tree-view.component';
import {AftermarketComponent} from './fleet/calculations/aftermarket/aftermarket.component';
import {FixedPriceComponent} from './fleet/calculations/fixed-price/fixed-price.component';
import {HourlyWageComponent} from './fleet/calculations/hourly-wage/hourly-wage.component';
import {OeArticleComponent} from './fleet/calculations/oe-article/oe-article.component';
import {NewFleetComponent} from './fleet/new-fleet/new-fleet.component';
import {FleetOverviewComponent} from './fleet/fleet-overview/fleet-overview.component';
import {AftermarketCalculationComponent} from './fleet/calculations/aftermarket/aftermarket-calculation/aftermarket-calculation.component';
import {HourlyWageCalculationComponent} from './fleet/calculations/hourly-wage/hourly-wage-calculation/hourly-wage-calculation.component';
import {StopPropagationDirective} from './shared/directives/stop-propagation.directive';
import {NewAftermarketCalculationComponent} from './fleet/calculations/aftermarket/new-aftermarket-calculation/new-aftermarket-calculation.component';
import {AftermarketCalculationInfoComponent} from './fleet/calculations/aftermarket/aftermarket-calculation-info/aftermarket-calculation-info.component';
import {BreadcrumbComponent} from './shared/components/breadcrumb/breadcrumb.component';
import {FixedPriceCalculationComponent} from './fleet/calculations/fixed-price/fixed-price-calculation/fixed-price-calculation.component';
import {NewFixedPriceCalculationComponent} from './fleet/calculations/fixed-price/new-fixed-price-calculation/new-fixed-price-calculation.component';
import {OeArticleCalculationComponent} from './fleet/calculations/oe-article/oe-article-calculation/oe-article-calculation.component';
import {NewOeArticleCalculationComponent} from './fleet/calculations/oe-article/new-oe-article-calculation/new-oe-article-calculation.component';
import {OeArticleCalculationInfoComponent} from './fleet/calculations/oe-article/oe-article-calculation-info/oe-article-calculation-info.component';
import {FixedPriceCalculationInfoComponent} from './fleet/calculations/fixed-price/fixed-price-calculation-info/fixed-price-calculation-info.component';
import {HourlyWageInfoComponent} from './fleet/calculations/hourly-wage/hourly-wage-info/hourly-wage-info.component';
import {NewHourlyWageCalculationComponent} from './fleet/calculations/hourly-wage/new-hourly-wage-calculation/new-hourly-wage-calculation.component';
import {FindCalculationComponent} from './fleet/calculations/find-calculation/find-calculation.component';
import {FleetMenuComponent} from './fleet/fleet-menu/fleet-menu.component';
import { CurrencySymbolPipe } from './shared/pipes/currency-symbol.pipe';
import { MaterialCodesComponent } from './fleet/material-codes/material-codes.component';
import { MaterialCodesViewComponent } from './fleet/material-codes/material-codes-view/material-codes-view.component';
import { NewMaterialCodesComponent } from './fleet/material-codes/new-material-codes/new-material-codes.component';
import { TreeItemComponent } from './fleet/material-codes/tree-item/tree-item.component';
import { MarkupOverviewComponent } from './fleet/calculations/markup/markup-overview/markup-overview.component';
import { NewMarkupComponent } from './fleet/calculations/markup/new-markup/new-markup.component';
import { MarkupComponent } from './fleet/calculations/markup/markup.component';
import { MarkupCalculationInfoComponent } from './fleet/calculations/markup/markup-calculation-info/markup-calculation-info.component';

@NgModule({
  declarations: [
    AppComponent,
    FleetComponent,
    NavbarComponent,
    ToastComponent,
    RootComponent,
    FleetInfoComponent,
    TreeViewComponent,
    AftermarketComponent,
    FixedPriceComponent,
    HourlyWageComponent,
    OeArticleComponent,
    NewFleetComponent,
    FleetOverviewComponent,
    AftermarketCalculationComponent,
    HourlyWageCalculationComponent,
    StopPropagationDirective,
    NewAftermarketCalculationComponent,
    AftermarketCalculationInfoComponent,
    BreadcrumbComponent,
    FixedPriceCalculationComponent,
    NewFixedPriceCalculationComponent,
    OeArticleCalculationComponent,
    NewOeArticleCalculationComponent,
    OeArticleCalculationInfoComponent,
    FixedPriceCalculationInfoComponent,
    HourlyWageInfoComponent,
    NewHourlyWageCalculationComponent,
    FindCalculationComponent,
    FleetMenuComponent,
    CurrencySymbolPipe,
    MaterialCodesComponent,
    MaterialCodesViewComponent,
    NewMaterialCodesComponent,
    TreeItemComponent,
    MarkupOverviewComponent,
    NewMarkupComponent,
    MarkupComponent,
    MarkupCalculationInfoComponent
  ],
  imports: [
    DragDropModule,
    AuthModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
