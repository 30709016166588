import {Component, OnDestroy, OnInit} from '@angular/core';
import {ICalculation} from "../../../../shared/interfaces/calculation.interface";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CalculationService} from "../../../../shared/services/calculation.service";
import {CalculationRestService} from "../../../../shared/services/rest-services/calculation-rest.service";
import {DataService} from "../../../../shared/services/data.service";
import {FleetService} from "../../../../shared/services/fleet.service";
import {HelperService} from "../../../../shared/services/helper.service";
import {debounceTime, take} from "rxjs/operators";
import {IGenArt} from "../../../../shared/interfaces/data.interface";

@Component({
  selector: 'app-new-markup',
  templateUrl: './new-markup.component.html'
})
export class NewMarkupComponent implements OnInit, OnDestroy {
  public currentId: number;
  public markupCalculation: ICalculation;
  public routeSubscription: Subscription;
  public _genArt: IGenArt;

  constructor(private route: ActivatedRoute,
              private calculationService: CalculationService,
              private calculationRestService: CalculationRestService,
              public dataService: DataService,
              private fleetService: FleetService,
              private router: Router,
              public helperService: HelperService) {


    this.markupCalculation = {
      id: null,
      type: 'MARKUP',
      sortNo: 1,
      fleetId: null,
      description: '',
      active: true,
      customId: '',
      unit: 'ANY',
      materialCode: '',
      quantity: 1,
      conditions: [],
      computationMap: {
        Flotte: {
          operation: '',
          value: null
        }
      }
    };

  }

  ngOnInit() {
    this.setFleedId();

    this.routeSubscription = this.route.parent.params.subscribe((params) => {
      this.currentId = Number(params.mId);
      if (this.currentId > 0) {
        this.calculationService.setCurrentCalculationById(this.currentId);
        this.calculationService.currentCalculation.pipe(debounceTime(500), take(1)).subscribe(data => {
          this.markupCalculation = data;
          this.changeOperations();
          this.setConditionsToModel();
        });
      }
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  public searchIndex(type: string): number {
    return this.markupCalculation.conditions.findIndex(item => item.conditionField === type);
  }


  public setFleedId() {
    this.markupCalculation.fleetId = this.fleetService.fleetId.value;
  }

  public setGenArt(event) {
    if (this.markupCalculation.conditions.some((element => element.conditionField === 'GEN_ART'))) {
      this.markupCalculation.conditions[this.searchIndex('GEN_ART')].value = event.item.id.toString();
    } else {
      this.markupCalculation.conditions.push({
        sortNo: 1,
        conditionField: 'GEN_ART',
        value: event.item.id.toString()
      });
    }
  }

  public setConditionsToModel() {
    this.markupCalculation.conditions.forEach((condition) => {
      if (condition.conditionField === 'GEN_ART') {
        this._genArt = {
          id: Number(condition.value),
          description: this.dataService.genArts[this.dataService.genArts.findIndex(item => item.id === Number(String(condition.value)))].description
        };
      }
    });
  }

  public setOperations() {
    this.markupCalculation.computationMap.Flotte.operation =
      (document.getElementsByName('fOp1')[0] as HTMLInputElement).value + '_' +
      (document.getElementsByName('fOp2')[0] as HTMLInputElement).value;

    if (this.markupCalculation.computationMap.Flotte.operation === 'CONST_CHARGE') {
      this.markupCalculation.computationMap.Flotte.operation = 'ADDITIONAL_CHARGE';
    }
  }

  public changeOperations() {
    if (this.markupCalculation.computationMap.Flotte.operation === 'ADDITIONAL_CHARGE') {
      this.markupCalculation.computationMap.Flotte.operation = 'CONST_CHARGE';
    }
  }


  public saveMarkupCalculation() {
    this.setOperations();

    if (this.currentId) {
      this.calculationRestService.updateCalculation(this.markupCalculation as ICalculation).subscribe(() => {
        this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'MARKUP');
      });
    } else {
      this.calculationRestService.createCalculation(this.markupCalculation as ICalculation).subscribe(
        () => {
          this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'MARKUP');
          setTimeout(() => {
            const calculations = [];
            if (this.calculationService.calculations) {
              this.calculationService.calculations.value.forEach(elem => {
                calculations.push(elem);
              });
            }
            const mapped = calculations.map(calc => {
              return {id: calc.id, sortNo: calc.sortNo + 1};
            });
          }, 250);
        });
    }
    void this.router.navigate(['/fleet/' + this.fleetService.fleetId.value + '/markup']);
  }

}
